import React from "react";
import { BubblyLink } from "../library/src";
import { useMediaQuery } from "react-responsive";
import { slide as Menu } from "react-burger-menu";
import menuStyles from "./menuStyles";

const MyBubblyLink = ({ to = "", text = "" }) => (
  <BubblyLink to={to} colorStart="#0077FF" colorEnd="#ffffff">
    {text}
  </BubblyLink>
);

export default function Nav() {
  const isMobile = useMediaQuery({ maxWidth: 900 });

  if (isMobile) {
    return (
      <Menu right styles={menuStyles}>
        <MyBubblyLink to="/" text="Home" />
        <MyBubblyLink to="/about" text="About me" />
        <MyBubblyLink to="/projects/" text="Projects" />
      </Menu>
    );
  }

  return (
    <nav className="animate-in rightNav" style={{ animationDelay: "600ms" }}>
      <div className="rightNav">
        <MyBubblyLink to="/" text="Home" />
        <MyBubblyLink to="/about" text="About me" />
        <MyBubblyLink to="/projects/" text="Projects" />
      </div>
    </nav>
  );
}
