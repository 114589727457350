const styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "20px",
    height: "20px",
    right: "40px",
    top: "50px",
    animationName: "in-keyframes",
    animationDuration: "0.5s",
    animationFillMode: "both",
    animationDelay: "600ms",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    width: "45%",
    height: "100%",
    top: "0px",
    boxShadow: "-5px 1px 36px -9px rgba(0,0,0,0.75)",
  },
  bmMenu: {
    background: "#fff",
    padding: "2.5em 1em",
    fontSize: "1.15em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    color: "#b8b7ad",
    gap: "0.5rem"
  },
  bmItem: {
    display: "flex",
    flexDirection: "column",
    color: "black",
    gap: "20px",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

export default styles;
