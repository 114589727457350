import ProjectCard from "../components/projectCard";
import Nav from "../shared/Nav";
import { Title } from "../shared/Title";

export default function Projects() {
  return (
    <>
      <header>
        <Title>Projects</Title>
        <Nav />
      </header>

      <section id="projects" className="projects-container slow-animate-in">
        <ProjectCard
          title="Stylesphere"
          text="Stylesphere is a sophisticated ecommerce platform that redefines fashion discovery and self-expression. With its intuitive interface and a curated array of elegant selections, Stylesphere empowers you to explore your unique style effortlessly. From everyday staples to exquisite couture."
          image="./StyleSphere.png"
          technologies="Next Typescript Axios Eslint Tailwind Prisma"
          right="yes"
          code="https://github.com/iolivera1/StyleSphere"
          />
        <ProjectCard
          title="StyleSphere - Admin Panel"
          text="The StyleSphere Admin Panel offers powerful control over your fashion empire. Manage products, categories, colors, billboards and more."
          image="./StyleSphere-admin.png"
          technologies="Next Typescript Axios Eslint Tailwind Prisma"
          code="https://github.com/iolivera1/StyleSphere-Admin-Panel"
          credentials
        />
        {/*<ProjectCard*/}
        {/*  title="DriveWise"*/}
        {/*  text="Drivewise is a rental car website that offers a seamless and convenient car rental experience. With a user-friendly interface and a wide selection of vehicles, Drivewise makes renting a car easy and efficient."*/}
        {/*  image="./drivewise.png"*/}
        {/*  technologies="React Node Tailwind Styled-components"*/}
        {/*  right="yes"*/}
        {/*  code="https://github.com/Iolivera1/DriveWise"*/}
        {/*  live="https://drivewise.iolivera.dev"*/}
        {/*/>*/}
        {/*<ProjectCard*/}
        {/*  title="Agendapp"*/}
        {/*  text="AgendApp is a powerful and user-friendly mobile and web application designed to simplify and streamline the reservation process for various services."*/}
        {/*  image="./agendapp.png"*/}
        {/*  technologies="Tailwind Laravel Vue MariaDB"*/}
        {/*  live="https://agendapp.stratus.uy"*/}
        {/*  code=""*/}
        {/*/>*/}
        <p>More projects coming soon! 🤠</p>
      </section>
    </>
  );
}
