import { ReactElement } from "react";

interface ISkillCard {
  icon: ReactElement<SVGElement>;
  title: string;
  description: string;
  theme?: "black" | "white";
}

export default function SkillCard(props: ISkillCard) {
  return (
    <>
      <div className="skill-card">
        {props.icon}
        <p>{props.title}</p>
        <p>{props.description}</p>
      </div>
    </>
  );
}
