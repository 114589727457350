import { FC, MouseEvent, ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";
import { BubblyBubbles } from "./BubblyBubbles";

export type BubblyLinkProps = {
  to: string;
  children: ReactNode;
  colorStart?: string;
  colorEnd?: string;
  duration?: number;
};

export const BubblyLink: FC<BubblyLinkProps> = ({
  to,
  children,
  colorStart = "#8f44fd",
  colorEnd = "#ffffff",
  duration = 1000,
}) => {
  const navigate = useNavigate();

  const handleClick = (e: MouseEvent<HTMLButtonElement> | undefined) => {
    e?.preventDefault();

    if (
      !document.getElementById("react-bubbly-transitions__bubbles") &&
      window.location.pathname !== to
    ) {
      window.history.pushState("", "", to);

      const container = createRoot(
        document.getElementById("react-bubbly-transitions__container")!
      );

      container.render(
        <BubblyBubbles
          colorStart={colorStart}
          colorEnd={colorEnd}
          duration={duration}
        />
      );

      setTimeout(() => navigate(to), duration / 2);

      setTimeout(() => container.unmount(), duration); 
    }
  };

  return (
    <button
      type="button"
      className={`react-bubbly-transitions__bubbly-link ${
        window.location.pathname === to ? "active" : ""
      }`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
