import { Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import Home from "./routes/Home";
import About from "./routes/About";
import Projects from "./routes/Projects";

import { BubblyContainer } from "./library/src";

export default function App() {
  return (
    <BrowserRouter>
      <BubblyContainer />
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<Home />} />
          <Route
            path="about"
            element={
              <Suspense fallback={<>...</>}>
                <About />
              </Suspense>
            }
          />
          <Route
            path="Projects"
            element={
              <Suspense fallback={<>...</>}>
                <Projects />
              </Suspense>
            }
          />
          <Route path="*" element={<>No Match</>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
